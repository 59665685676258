import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CgFacebook, CgGoogle, CgInstagram, CgMail, CgPhone } from 'react-icons/cg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import logo from '../components/images/logo_bg_none.png';
// import navAngle from '../components/images/nav_golden_angle.png';


class NavTop extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY >= 600 && !this.state.scrolled) {
      this.setState({ scrolled: true });
    } else if (window.scrollY < 600 && this.state.scrolled) {
      this.setState({ scrolled: false });
    }
  };
  
  render() {
    const { pathname } = window.location;
    return (
      <div className={this.state.scrolled ? 'bg-black position-fixed w-100 fixed-nav' : 'bg-black position-relative z-3 w-100 fixed-nav'}>
        <div className='btn_bg_golden nav_top_golden position-absolute fixed-nav pe-5 py-1 top-0 end-0'>
              <div className='container'>
                <div className='d-flex justify-content-end gap-3 nav_top_contact_card'>
                  <div className='text-white d-flex gap-2 align-items-center nav_top_contact'>
                    <CgPhone className='fs-4'/>
                    <Link className='text-white text-decoration-none fw-bold' to='tel:+917003494268'>7003494268</Link>
                  </div>
                  <div className='text-white d-flex gap-2 align-items-center nav_top_contact'>
                    <CgMail className='fs-4'/>
                    <Link className='text-white text-decoration-none fw-bold' to='mailto:add2486@gmail.com'>add2486@gmail.com</Link>
                  </div>
                </div>
              
              </div>
        </div>
        <Navbar expand="lg" className="bg-transparent">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand className='mobile_logo position-absolute'><Link to='/'><img src={logo} className='nav_band_logo d-block d-lg-none' alt="Logo" /></Link></Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between'>
              <Navbar.Brand><Link to='/'><img src={logo} className='nav_band_logo d-lg-block d-none' alt="Logo" /></Link></Navbar.Brand>
              <Nav className="align-items-center gap-4 mt-4 nav_menu">
                <Nav.Link className={`text-white ${pathname === '/' ? 'color_border active' : ''}`} as={Link} to='/'>Home</Nav.Link>
                <Nav.Link className={`text-white ${pathname === '/about' ? 'color_border active' : ''}`} as={Link} to='/about'>About</Nav.Link>
                <Nav.Link className={`text-white ${pathname === '/menu' ? 'color_border active' : ''}`} as={Link} to='/menu'>Menu</Nav.Link>
                <Nav.Link className={`text-white ${pathname === '/gallery' ? 'color_border active' : ''}`} as={Link} to='/gallery'>Gallery</Nav.Link>
                <Nav.Link className={`text-white ${pathname === '/contact' ? 'color_border active' : ''}`} as={Link} to='/contact'>Contact Us</Nav.Link>
              </Nav>
              <Nav className="align-items-center gap-4 mt-4">
                <Nav.Link className='color_golden nav_top_social' as={Link} target='_blank' to="https://www.facebook.com/dosacafekol/"><CgFacebook/></Nav.Link>
                <Nav.Link className='color_golden nav_top_social' as={Link} target='_blank' to="#"><CgGoogle/></Nav.Link>
                <Nav.Link className='color_golden nav_top_social' as={Link} target='_blank' to="#"><CgInstagram/> </Nav.Link>
                <Nav.Link className='text-white' to="">
                  <button className='border-0 rounded-2 btn_bg_golden px-3 py-2 text-white fw-bold'>
                    <span className='position-relative'>Book Table</span>
                  </button>
                  </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}



export default NavTop;
