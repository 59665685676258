import React from 'react';
// import bnrFood from './images/banner_food.png';
import vagitables from './images/vegitables_small.png';
import veg1 from './images/tomato_small.png';
import veg2 from './images/brocoli_small.png';
import OwlCarousel from 'react-owl-carousel';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import { CgArrowDown, CgEditBlackPoint, CgMouse, CgShare } from 'react-icons/cg';
import fd4 from './images/banner/1.png';
import fd3 from './images/banner/2.png';
import fd1 from './images/banner/3.png';



const options = {
  loop:true,
  items:1,
  autoplay:true,
  autoplayTimeout:3000,
  autoplayHoverPause:true,
  smartSpeed:20000,
  margin:10,
  dots:false,
  nav:false,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn' 
}

const VideoBanner = () => {
  return (
    <div className='banner_video postion-relative align-items-center d-flex'>
        
      <div className='container'>
        <div className='header_content position-absolute d-flex justify-content-center start-0 w-100 h-100 top-0 z-0'>
          <span className='card_png'>
            <img className='veg1 position-absolute z-1' src={veg1} alt=''/>
            <img className='veg2 position-absolute z-1' src={veg2} alt=''/>
            <img className='veg3 position-absolute z-1' src={veg1} alt=''/>
            <img className='veg4 position-absolute z-1' src={veg2} alt=''/>
          </span>
        </div>
        <div className='position-absolute z-0 text-white text-center w-100 start-0 top-0 mt-5 pt-5'>
          {/* <p className='span_headline banner_headline mb-0 mt-3'>Taiba <span className='span_golden span_headline fs-1'>Kitchen</span> </p>
          <img className='bnrFood' src={vagitables} alt=''/> */}
        </div>

        {/*  */}

        <div className='row align-items-center z-1'>
                
                <div className='col-xl-6 col-lg-6'>
                    <span className='span_golden span_headline'>Welcome to</span>
                    <h3 className='color_golden banner_headline'>
                      DOSA CAFE
                    </h3>
                    <p className='text-white'>
                      Where Tradition Meets Taste. Savory Dosas, Authentic Biryani, and Gaouti Kebabs Crafted to Perfection!
                    </p>
                    <button className='border-0 btn_bg_golden px-3 py-2 text-white fw-bold rounded'>
                        <span className='position-relative'>More About Us</span>
                    </button>
                </div>

                <div className='col-xl-6 col-lg-6'>

                <OwlCarousel {...options} className='owl-theme owl_offer position-relative' loop margin={10} nav>
                    {/* <div className='item'>
                        <div className='card border-0 bg-transparent p-5 biriyani_card'>
                            <div className='about_imgs'>
                                <img src={fd4} alt='' className='aboutResturant'/>                    
                            </div>
                        </div>
                    </div> */}

                    <div className='item'>
                        <div className='card border-0 bg-transparent p-5 galouti_card'> 
                            <div className='about_imgs'>
                                <img src={fd3} alt='' className='aboutResturant'/>                    
                            </div>
                        </div>               
                    </div>
                    <div className='item'>
                        <div className='card border-0 bg-transparent p-5 galouti_card'> 
                            <div className='about_imgs'>
                                <img src={fd1} alt='' className='aboutResturant'/>                    
                            </div>
                        </div>               
                    </div>
                
                </OwlCarousel>
                    {/* <div className='about_imgs'>
                        <img src={biriyani} alt='' className='aboutResturant'/>                    
                    </div> */}
                </div>
            </div>
        {/*  */}
        
        {/* <OwlCarousel {...options} className='owl_head_banner owl-theme owl_banner position-relative' loop margin={10} nav>
            <div className='item'>
                <div className='card border-0 bg-transparent'>
                    <div className='d-flex align-items-center p-5 justify-content-between'>
                        <div className='chefDetails'>
                          <h5 className='color_golden display-4 fw-bold'>Welcome to</h5>
                          <h4 className='text-white display-1'>Dosa Cafe</h4>                           
                        </div>  
                        <div>
                          
                        </div>                    
                    </div>
                </div>
            </div>
            <div className='item'>
                <div className='card border-0 bg-transparent'>
                    <div className='d-flex align-items-center p-5 justify-content-between'>
                        <div className='chefDetails'>
                        <h5 className='color_golden display-4 fw-bold'>Welcome to</h5>
                          <h4 className='text-white display-1'>Dosa Cafe</h4>        
                        </div>  
                        <div className=''>
                            
                        </div>                       
                    </div>
                </div>               
            </div>
        
        </OwlCarousel> */}
        <div className='mouse_icon w-100 start-0 bottom-0 d-flex fs-4 justify-content-center align-items-center text-white mb-5 flex-column position-absolute' >
          <CgMouse className='color_golden'/>
          <span className='color_golden'>⁞</span>
          <CgArrowDown className='color_golden'/>
        </div> 
      </div>
        
    </div>
  )
}

export default VideoBanner