import React from 'react';
import MainLayout from '../layout/MainLayout';
import GalleryMain from '../components/GalleryMain';

const GalleryPage = () => {
  return (
    <MainLayout>
        <div>
        <div className='about_header d-flex justify-content-center align-items-center py-5 flex-column'>
          <h2 class="display-2 text-white py-5 position-relative z-1">Gallery</h2>
          <p className='text-white position-relative z-1 w-50 text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate dolorum exercitationem natus alias, porro molestias officiis, aut iste magnam, non consequatur pariatur. Nobis totam culpa soluta temporibus quas omnis placeat.</p>
          {/* <h2 className='fs-2 text-white py-5'>Gallery</h2> */}
        </div>
        <GalleryMain/>
        </div>
      </MainLayout>
  )
}

export default GalleryPage