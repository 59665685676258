import React from 'react';
// import {FaTicketAlt} from 'react-icons/fa';
// import {CgIcecream} from 'react-icons/cg';
// import {CgPushUp} from 'react-icons/cg';
// import aboutFood from './images/about_food.jpg';
import OwlCarousel from 'react-owl-carousel';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import chosma from './images/png/23.png';
import galouti from './images/galouti.png';
// import blueSmoke from './images/blue_smoke.jpg';
import absLeft from './images/abs_left.png';
import absRight from './images/abs_right.png';
import topLeft from './images/top_left.png';
import topRight from './images/top_right.png';
import palace from './images/kolkata-sketch-10.jpg';




const options = {
    loop:true,
    items:1,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    smartSpeed:2000,
    margin:10,
    dots:false,
    nav:false,
  }


const BestOffer = () => {
  return (
    <>
    <div className='home_about pb-5 position-relative'> 
    <img src={absLeft} className='position-absolute start-0 absLeft' alt='abstract-1'/>
    <img src={absRight} className='position-absolute end-0 absRight' alt='abstract-2'/>
    <img src={palace} className='position-absolute bottom-0 palace' alt='abstract-3'/>
    {/* <img className='position-absolute blueSmoke bottom-0 start-0 w-100 h-100' src={blueSmoke} alt=''/> */}
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-xl-6 col-lg-6'>

                {/* <OwlCarousel {...options} className='owl-theme owl_offer position-relative' loop margin={10} nav>
                    <div className='item'>
                        <div className='card border-0 bg-transparent p-5 biriyani_card'>
                            <div className='about_imgs'>
                                <img src={biriyani} alt='' className='aboutResturant'/>                    
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='card border-0 bg-transparent p-5 galouti_card'> 
                            <div className='about_imgs'>
                                <img src={galouti} alt='' className='aboutResturant'/>                    
                            </div>
                        </div>               
                    </div>
                
                </OwlCarousel> */}
                    <div className='about_imgs'>
                        <img src={chosma} alt='' className='aboutResturant'/>                    
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6'>
                    <span className='span_golden span_headline pb-4'>About company</span>
                    <h3 className='display-3'>
                        Welcome to Dosa Cafe<br/>
                        <span className='span_golden span_headline'>– Where Culinary Delights Await!</span> 
                    </h3>
                    <p>
                        Indulge your senses in a symphony of flavors at Dosa Cafe, where we artfully blend the rich 
                        heritage of biryani, the succulence of galouti kebabs, and the crisp perfection of dosas. 
                        Our culinary haven invites you on a gastronomic journey that celebrates the essence of 
                        Indian cuisine.
                    </p>
                    <button className='border-0 btn_bg_golden px-3 py-2 mt-2 text-white fw-bold rounded'>
                        <span className='position-relative'>More About Us</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default BestOffer

