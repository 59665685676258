import React, { useState, useEffect } from 'react';
import Image from './Image';

const ImageGallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://dosacafe.in/admin/cafeApi/getGalleryItems');
        if (!response.ok) {
          throw new Error('Error fetching images');
        }
        const data = await response.json();
        console.log("Fetched images:", data); // Log fetched images
        setImages(data.map((item, index) => ({
          id: index + 1,
          url: item.gallery_img, // Use the correct property name for the image URL
          category: item.category
        })));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  // Render ImageGallery only when images are fetched
  return (
    <div className="image-gallery container d-flex flex-wrap gap-5">
      {images.length > 0 ? (
        images.map((image) => (
          <Image key={image.id} imageUrl={image.url} />
        ))
      ) : (
        <p>Loading images...</p>
      )}
    </div>
  );
};

export default ImageGallery;
