import React, {useState} from 'react';
import logo from '../components/images/logo_bg_none.png';
import taibalogo from '../components/images/logo1.png';
import { Link } from 'react-router-dom';
import { CgArrowRight, CgGoogle, CgMail, CgPhone } from 'react-icons/cg';
import { FaMapMarkerAlt, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import zomato from './images/zomato.png';
import swiggy from './images/swiggy.png';


const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleLinks = () => {
        setIsOpen(!isOpen);
    };
  return (
    <>
    <footer className='position-relative'>
        <div className="progress rounded-0" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
            <div className="bg-dark progress-bar progress-bar-striped progress-bar-animated" style={{width: '100%'}}></div>
        </div>
        <div className='uppperFooter pt-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xl-7 col-lg-7 col-md-12'>
                        <div className='row'>
                        <div className='col-12 logo_col_footer position-relative py-5'>
                            <div className='card bg-transparent border-0 footer_logo_card'>
                                <div className='footer_logo d-flex align-items-center gap-3'>
                                    {/* <Link to='/'>
                                        <img className='' src={taibalogo} alt=''/>
                                    </Link> */}
                                    <Link to='/'>
                                        <img className='' src={logo} alt=''/>
                                    </Link> 
                                    <p className='m-0 text-white ms-auto text-end'>
                                        Visit us today and embark on a journey of taste at Dosa Cafe<br/>
                                        <span className='span_golden span_headline fs-6'>- Where Every Bite Tells a Story! </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-xl-3 col-lg-3 col-md-6 mt-5 position-relative'>
                            <div className='card text-white bg-transparent border-0 gap-3 ps-3 rounded-0 footer_border_left'>
                            <h4 className={`color_border span_golden span_headline fs-3 ${isOpen ? 'res_minus' : 'res_plus'}`} onClick={toggleLinks}>
                                Quick Links<FaPlusCircle className='position-absolute color_golden footer_plus'/>
                                {isOpen ? <FaMinusCircle className='position-absolute color_golden footer_plus' /> : <FaPlusCircle className='position-absolute color_golden footer_plus' />}
                                
                            </h4>
                            <ul className={`list-unstyled ft_li ${isOpen ? 'res_open' : 'res_hide'}`}>
                                <li className='mb-3'>
                                    <Link className='text-decoration-none text-white quick_link' to='/menu'>
                                        Menu Card <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow' />
                                    </Link>
                                </li>
                                <li className='mb-3'>
                                    <Link className='text-decoration-none text-white quick_link' to='/about'>
                                        About Us  <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow' />
                                    </Link>
                                </li>
                                <li>
                                    <Link className='text-decoration-none text-white quick_link' to='/contact'>
                                        Visit  <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow' />
                                    </Link>
                                </li>
                            </ul>

                            </div>
                            
                        </div>
                        <div className='col-xl-1 col-lg-3 col-md-6 mt-5 d-none'>
                        {/* <div className='card text-white bg-transparent border-0 gap-3 ps-3 rounded-0 footer_border_left'>
                                <h4 className='color_border span_golden span_headline fs-3'>Our Services</h4>
                                <ul className='list-unstyled ft_li'>
                                    <li className='mb-3'>
                                        <Link className='text-decoration-none text-white quick_link' to=''>
                                            Services 1 <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow'/>
                                        </Link>
                                    </li>
                                    <li className='mb-3'>
                                    <Link className='text-decoration-none text-white quick_link' to=''>
                                        Services 2 <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow'/>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link className='text-decoration-none text-white quick_link' to=''>
                                        Services 3  <CgArrowRight className='color_golden fs-4 ms-2 footer_arrow'/>
                                    </Link>                                    
                                    </li>

                                </ul>
                            </div> */}
                        </div>
                    
                        <div className='col-xl-8 col-lg-6 col-md-6 mt-5'>
                        <div className='add_pad card text-white bg-transparent border-0 gap-3 rounded-0 footer_border_left'>
                                <h4 className='color_border span_golden span_headline fs-3'>Contact Us</h4>
                                <ul className='list-unstyled ft_li'>
                                    <li className='mb-3 d-flex align-items-center'>
                                        <CgPhone className='color_golden me-3 fs-5'/> 
                                        <Link className='text-decoration-none text-white fw-bold contact_link' to='tel:+917003494268'>7003494268</Link>
                                    </li>
                                    <li className='mb-3 d-flex align-items-center'>
                                        <CgMail className='color_golden me-3 fs-5'/> 
                                        <Link className='text-decoration-none text-white fw-bold contact_link' to='mailto:add2486@gmail.com'>add2486@gmail.com</Link>
                                    </li>
                                    <li className='d-flex align-items-center'>
                                        <FaMapMarkerAlt className='color_golden me-3 fs-5'/> 
                                        <Link target='_blank' className='text-decoration-none text-white fw-bold contact_link' to='https://maps.app.goo.gl/p2pDRMBEPd54XLDj8'>
                                            14 A Nasiruddin Road, Kolkata-700016
                                        </Link>                                    
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className='col-12 mobile_absolute'>
                            <div className='lowerFooter container position-relative'>
                                <div className='d-flex justify-content-between align-items-center py-3'>
                                    <div className='reserved_footer'>
                                        <p className='text-white m-0'>
                                            © 2023 
                                            <Link className='ms-1 text-decoration-none color_golden fw-bold' to={'/'}>
                                                Dosa Cafe
                                            </Link>. All rights reserved.
                                        </p>
                                        <p className='text-white m-0'>
                                            Designed & Developed By 
                                            <Link className='ms-1 text-decoration-none color_golden fw-bold' target='_blank' to={'https://kyleinfotech.co.in/'}>
                                                Kyle Infotech
                                            </Link>
                                        </p>
                                    </div>
                                    <div className='delivery_agents d-flex align-items-center gap-4'>                                    
                                        <img className='swiggy' src={swiggy}/>
                                        <img className='zomato' src={zomato}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className='col-xl-5 col-lg-5 col-md-5'>
                        <div className='facebook_iframe card text-white bg-transparent border-0 ps-5 gap-3'>
                            <iframe title='Facebook Page' height='410' className='m-auto pt-2 w-100' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdosacafekol%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </footer>
    </>
  )
}

export default Footer