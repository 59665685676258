import React from 'react';
import OurMenu from '../components/OurMenu.js';
// import Team from '../components/Team';
// import Reviews from '../components/Reviews';
import ReserveForm from '../components/ReserveForm';
// import CafeItemList from "./components/CafeItemList";
import Banner from '../components/Banner';
import BestOffer from '../components/BestOffer';
import MainLayout from '../layout/MainLayout';
import VideoBanner from '../components/VideoBanner.js';
import TodaySpecial from '../components/TodaySpecial.js';
import GoogleReviews from '../components/GoogleReviews.js';

const Home = () => {
  return (
    <MainLayout>
      <VideoBanner/>
      
      <BestOffer/> 
      <TodaySpecial/>
      <section className='home_menu'>
       <OurMenu/>
      </section>
      
      
      {/* <CafeItemList/> */}
      <ReserveForm/>
      {/* <Team/> */}
      <GoogleReviews/>
      {/* <Reviews/>  */}
      <Banner/>
    </MainLayout>
  )
}

export default Home