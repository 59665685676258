// src/data/images.js

// import fd1 from '../components/images/na/fd1.jpg';
// import fd2 from '../components/images/na/fd2.jpg';
// import fd3 from '../components/images/na/fd3.jpg';
// import fd4 from '../components/images/na/fd4.jpg';
// import fd5 from '../components/images/na/fd5.jpg';
// import inner1 from '../components/images/na/inner1.jpg';
// import inner2 from '../components/images/na/inner2.jpg';
// import out1 from '../components/images/na/out1.jpg';
// import out2 from '../components/images/na/out2.jpg';
// import out3 from '../components/images/na/out3.jpg';
// import out4 from '../components/images/na/out4.jpg';
// const images = [
//     {
//       id: 1,
//       url: fd1,
//       category: 'Food',
//     },
//     {
//       id: 2,
//       url: fd2,
//       category: 'Food',
//     },
//     {
//       id: 3,
//       url: fd3,
//       category: 'Food',
//     },
//     {
//       id: 4,
//       url: fd4,
//       category: 'Food',
//     },
//     {
//       id: 5,
//       url: fd5,
//       category: 'Food',
//     },
//     {
//       id: 6,
//       url: inner1,
//       category: 'interior',
//     },
//     {
//       id: 7,
//       url: inner2,
//       category: 'interior',
//     },
//     {
//       id: 8,
//       url: out1,
//       category: 'outer',
//     },
//     {
//       id: 9,
//       url: out2,
//       category: 'outer',
//     },
//     {
//       id: 10,
//       url: out3,
//       category: 'outer',
//     },
//     {
//       id: 11,
//       url: out4,
//       category: 'outer',
//     },
//   ];
  
//   export default images;

// Define an empty array to store fetched images
let images = [];

// Fetch data from the CodeIgniter endpoint
fetch('https://dosacafe.in/admin/cafeApi/getGalleryItems')
  .then(response => response.json())
  .then(data => {
    // Map the fetched data to match the format of your image objects
    images = data.map((item, index) => ({
      id: index + 1,
      url: item.url, // Adjust this according to the structure of your fetched data
      category: item.category // Adjust this according to the structure of your fetched data
    }));
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });

// Export the images array
export default images;
  