import React, { useEffect, useState } from 'react';
// import items from '../data/items';
import MenuGallery from '../components/MenuGallery';
import MenuCategoryFilter from '../components/MenuCategoryFilter';
import abstract from '../components/images/abstract.png';
import idli from './images/na/itly.png';
import topRight from './images/png/9.png';



const OurMenu = ({items}) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://dosacafe.in/admin/cafeApi/getAllItems')
      .then(response => response.json())
      .then(data => {
        console.log(data); // Handle the data received from the API
        setData(data); // Update the component state with the data
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error(error); // Handle any errors
        setLoading(false); // Set loading to false in case of errors
      });
  }, []);
  

  const [selectedCategory, setSelectedCategory] = useState('');
  const categories = [...new Set(data.map((item) => item.category))].map((category) => {
    return {
      category: category,
      catImg: data.find((item) => item.category === category).catImg,
    };
  });
  
  
  const filteredItems = selectedCategory
      ? data.filter((item) => item.category === selectedCategory)
      : data;

      const handleCategorySelect = (category) => {
        console.log('Selected Category:', category);
        setSelectedCategory(category);
      };
      

  if (loading) {
    // Render a loading message or spinner while data is being fetched
    return <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>;
  }
return (
  <>
  <div className='menu_main position-relative'>
    <img src={idli} className='position-absolute top-0 start-0 top_left' alt='abstract-3'/>
    <img src={topRight} className='position-absolute top-0 end-0 top_left' alt='abstract-4'/>
      <div className='container py-5' id='product_items'>
      
          <div className='d-flex flex-column menu_headline'>
          <span className='span_golden span_headline text-center'>Best Food Menu</span>
              <h2 className='text-center'>Our Menu</h2>
          </div>
          
          <MenuCategoryFilter
            categories={categories}
            selectedCategory={selectedCategory}
            onSelectCategory={handleCategorySelect}
          />
          <MenuGallery data={filteredItems} />

      </div>
  </div>
  </>
)
}

export default OurMenu

