import React from 'react';
import MainLayout from '../layout/MainLayout';
// import ReserveForm from '../components/ReserveForm';
// import blueSmoke from '../components/images/blue_smoke.jpg';
import Accordion from 'react-bootstrap/Accordion';
import kitchen from '../components/images/kitchen.jpg';

const AboutUs = () => {
  return (


      <MainLayout>
        <div className='about_header d-flex justify-content-center align-items-center py-5 flex-column'>
          <h2 class="display-2 text-white py-5 position-relative z-1">About Us</h2>
          <p className='text-white position-relative z-1 w-50 text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate dolorum exercitationem natus alias, porro molestias officiis, aut iste magnam, non consequatur pariatur. Nobis totam culpa soluta temporibus quas omnis placeat.</p>
          {/* <h2 className='fs-2 text-white py-5 m-0'>About Us</h2> */}
        </div>
        <div className='container py-5'>
                <h1 className='color_border display-5 mb-4'>Indulge Your Palate at Taiba Kitchen</h1>
                <p className='mb-3'>
                    Welcome to Taiba Kitchen, your ultimate destination for a gastronomic adventure that seamlessly blends the rich flavors of Indian cuisine with the cozy ambiance of a cafe. Nestled in the heart of [Location], Taiba Kitchen stands out as a beacon for food enthusiasts seeking an authentic and diverse dining experience. As a versatile cafe, it proudly introduces Dosa Cafe, offering a delectable array of South Indian delicacies alongside a menu that boasts mouthwatering biryani, succulent galouti kebabs, and more.
                </p>
          <div className='row align-items-center g-0 py-5'>
            <div className='col-xl-6'>
              <div className='card border-0 rounded-0 scale_ch'>
                <img className='w-100' src={kitchen} alt=''/>
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='card p-4'>
                
                <h4 className='span_golden span_headline'>Biryani Bliss and Kebab Extravaganza:</h4>
                <p>
                  Taiba Kitchen takes pride in presenting a culinary symphony that goes beyond dosas. Our biryani, cooked to perfection, is a sensory delight that captures the essence of aromatic spices and tender meat, creating a harmonious blend that's nothing short of exquisite. For those who savor the rich taste of kebabs, our galouti kebabs are a true masterpiece, embodying the royal culinary heritage of India.
                </p>
              </div>
            </div>
          </div>
          
        </div>

        <div className='why_choose_us position-relative pb-5'>
          <div className='container'>
            <div className='row align-items-center g-0'>
              <div className='col-xl-6'>
                <div className='card p-4 border-0 bg-transparent'>
                  <h3 className='fs-1 display-1 mb-3'>Why Choose Taiba Kitchen?</h3>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className=' span_golden span_headline'>Diverse Menu:</Accordion.Header>
                      <Accordion.Body>
                          Taiba Kitchen offers a diverse menu that caters to the varied tastes of our patrons. Whether you're a fan of crispy dosas, flavorful biryanis, or succulent kebabs, we have something for everyone.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header className=' span_golden span_headline'>Quality Ingredients:</Accordion.Header>
                      <Accordion.Body>
                        We believe in using only the finest and freshest ingredients in our kitchen, ensuring that every dish is a testament to quality and taste.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header className=' span_golden span_headline'>Warm Ambiance:</Accordion.Header>
                      <Accordion.Body>
                          Taiba Kitchen provides a cozy and inviting ambiance, making it the perfect place to unwind with friends and family while enjoying a delightful meal.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header className=' span_golden span_headline'>Expert Chefs:</Accordion.Header>
                      <Accordion.Body>
                          Our chefs are skilled artisans, passionate about their craft and dedicated to creating culinary masterpieces that reflect the authenticity of Indian cuisine.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>

              <div className='col-xl-6'>
                <div className='card border-0 rounded-0 scale_ch z-n1'>
                  <img className='w-100' src={kitchen} alt=''/>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        {/* <ReserveForm/> */}
      </MainLayout>

  )
}

export default AboutUs