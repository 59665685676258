import React from 'react';
import MainLayout from '../layout/MainLayout';
import { CgMail, CgPhone, CgTimer } from 'react-icons/cg';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ReserveForm from '../components/ReserveForm';
import decoImg1 from '../components/images/abs_left.png';
import decoImg2 from '../components/images/abs_right.png';

const ContactUs = () => {
  return (
    <MainLayout>
        <div>
        <div className='about_header d-flex justify-content-center align-items-center py-5 flex-column'>
          <h2 class="display-2 text-white py-5 position-relative z-1">Contact Us</h2>
          <p className='text-white position-relative z-1 w-50 text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate dolorum exercitationem natus alias, porro molestias officiis, aut iste magnam, non consequatur pariatur. Nobis totam culpa soluta temporibus quas omnis placeat.</p>
          {/* <h2 className='fs-2 text-white py-5'>Contact Us</h2> */}
        </div>
        
          <div className='contact_details'>
            <div><img className='decoration_img_1' src={decoImg1} alt=''/></div>
            <div><img className='decoration_img_2' src={decoImg2} alt=''/></div>
          <div className='container my-5'>
            <div className='row py-5 align-items-center'>
              <div className='col-xl-6 ps-5'>
                <div className='card border-0 card_contact flex-row gap-3 bg-transparent align-items-baseline'>
                  <div className='con_card_icon span_golden fs-4'><FaMapMarkerAlt/></div>
                  <div className='z-0 rounded'>
                    <h5 className='span_golden span_headline'>
                      Location
                    </h5>
                    <p className='fw-bold fs-5 text-secondary'>
                        14 A Nasiruddin Road, Kolkata-700016
                    </p>
                  </div>
                </div>
                <div className='card border-0 card_contact flex-row gap-3 bg-transparent align-items-baseline'>
                  <div className='con_card_icon span_golden fs-4'><CgPhone/></div>
                  <div className='z-0 rounded'>
                    <h5 className='span_golden span_headline'>
                      Phone Number
                    </h5>
                    <p className='fw-bold fs-5 text-secondary'>
                     7003494268
                    </p>
                  </div>
                </div>
                <div className='card border-0 card_contact flex-row gap-3 bg-transparent align-items-baseline'>
                  <div className='con_card_icon span_golden fs-4'><CgMail/></div>
                  <div className='z-0 rounded'>
                    <h5 className='span_golden span_headline'>
                      Email Address
                    </h5>
                    <p className='fw-bold fs-5 text-secondary'>
                      add2486@gmail.com
                    </p>
                  </div>
                </div>
                <div className='card border-0 card_contact flex-row gap-3 bg-transparent align-items-baseline'>
                  <div className='con_card_icon span_golden fs-4'><CgTimer/></div>
                  <div className='z-0 rounded'>
                    <h5 className='span_golden span_headline'>
                      Open & Closing
                    </h5>
                    <p className='fw-bold fs-5 text-secondary'>
                      Mon-Sat: 9AM to 10PM
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 pe-5'>
              <ReserveForm/>
              </div>
            </div>
          </div>
          </div>

          

          

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.0101674421617!2d88.3616327752996!3d22.541291879514038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02776d4de51ee9%3A0xd91b044d889bb69e!2sDOSA%20CAFE!5e0!3m2!1sen!2sin!4v1700812333227!5m2!1sen!2sin" 
            className='w-100'
            height="450"
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Maps">
          </iframe>

        </div>
      </MainLayout>
  )
}

export default ContactUs