import React, { Fragment } from 'react';
import Carousel from '../../node_modules/react-bootstrap/Carousel';
import CarImageOne from './CarImageOne';
import CarImageTwo from './CarImageTwo';
import CarImageThree from './CarImageThree';
import {CgMouse} from 'react-icons/cg';
// import CarImage2 from '../images/car1.jpg';
import biriyani from '../components/images/na/dosa_bg.webp';


function Banner() {
  return (
    <Fragment>

    <div className='offer_banner position-relative'>
        <div className="progress rounded-0" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
            <div className="bg-dark progress-bar progress-bar-striped progress-bar-animated" style={{width: '100%'}}></div>
        </div>
      <div className='banner_offer_image'>
        <img src={biriyani} alt='' className='w-100'/>
      </div>

      <span className='position-absolute w-100 d-flex align-items-center h-100 top-0 z-1'>
        <div className='container'>
        <h3 className='w-50 text-white display-4 banner_h3'>
         <span className='fs-5 fw-bold color_golden'>Embark on a Culinary Journey</span> <br/>
          <span className='fs-2'> Where Every Crisp Bite Unfolds a Symphony of South Indian Delight!</span> 
        </h3>
        </div>
      </span>

    </div>

    </Fragment>
  )
}

export default Banner