import React, { useEffect, useState } from 'react';
import Menu from './Menu';

const MenuGallery = ({ data }) => { // Updated the prop name to 'data'

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false); // Remove the fetch logic for data, as it's now received as a prop
  }, []);

  if (loading) {
    // Render a loading message or spinner while data is being fetched
    return <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>;
  }
  return (
    <div className='row py-3 gx-5 menu_row position-relative'>
      <div className='menu_left'></div>
      <div className='menu_right'></div>
      {data && data.length > 0 ? (
        data.map(item => (
          <Menu
            key={item.id}
            imageUrl={`https://dosacafe.in/admin/${item.food_image}`}
            itemName={item.food_name}
            itemPrice={item.price}
            itemDescription={item.short_des}
          />
        ))
      ) : (
        <p>No items available</p>
      )}
    </div>
  );
};

export default MenuGallery;
