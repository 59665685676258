import React from 'react';

const GoogleReviews = () => {
  return (
    <div className='google_reviews py-5'>
        <div className='container'>
          <span className="span_golden span_headline text-center d-block">Our Google Reviews</span>
          <div className="elfsight-app-76fc3af1-b037-4692-9d64-e3d8ebffbe8a" data-elfsight-app-lazy></div>
        </div>
    </div>
  )
}

export default GoogleReviews